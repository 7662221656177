<template>
  <Modal
      :footer-hide="true"
      v-model="modal"
      :mask-closable="false"
      :lock-scroll="true"
      class-name="vertical-center-modal"
  >
    <div class="login_right">
      <p class="reg"> {{ $t('uc.regist.regist') }}</p>
      <Form v-if="allowRegister" ref="formInline" :model="formInline" :rules="ruleInline" inline
            style="margin-top: 5%;">
        <FormItem prop="user" class="captcha">
          <Input type="text" size="large" v-model="formInline.user" :placeholder="$t('uc.login.usertip')">
            <Select v-model="country" slot="prepend" style="width:85px;">
              <Option value="+855" label="+855"><span>+855</span><span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.cambodia') }}</span></Option>
              <Option value="+86" label="+86"><span>+86</span><span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.china') }}</span></Option>
              <Option value="+65" label="+65"><span>+65</span><span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.singapore') }}</span></Option>
              <Option value="+82" label="+82"><span>+82</span><span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.korea') }}</span></Option>
              <Option value="+81" label="+81"><span>+81</span><span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.japan') }}</span></Option>
              <Option value="+66" label="+66"><span>+66</span><span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.thailand') }}</span></Option>
              <Option value="+44" label="+44"><span>+44</span><span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.uk') }}</span></Option>
              <Option value="+84" label="+84"><span>+84</span><span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.vietnam') }}</span></Option>
              <Option value="+62" label="+62"><span>+62</span><span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.india') }}</span></Option>
              <Option value="+39" label="+39"><span>+39</span><span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.italy') }}</span></Option>
              <Option value="+852" label="+852"><span>+852</span><span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.hk') }}</span></Option>
              <Option value="+60" label="+60"><span>+60</span><span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.malaysia') }}</span></Option>
              <Option value="+886" label="+886"><span>+886</span><span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.taiwan') }}</span></Option>
              <Option value="+673" label="+673"><span>+673</span><span
                  style="margin-left:10px;color:#ccc">{{ $t('uc.regist.wenlai') }}</span></Option>
            </Select>
          </Input>
        </FormItem>
        <FormItem prop="code" class="captcha" v-show="showCode">
          <Input type="text" size="large" v-model="formInline.code" :placeholder="$t('uc.regist.smscode')">
          </Input>
          <input id="sendCode" @click="sendCode();" type="Button"
                 style="margin-top:10px;height: 100%;color:#fff;border:none"
                 shape="circle" :value="sendcodeValue" :disabled='codedisabled'/>
        </FormItem>
        <FormItem prop="password" class="captcha">
          <Input type="password" size="large" v-model="formInline.password" password
                 :placeholder="$t('uc.regist.pwd')">
          </Input>
        </FormItem>
        <FormItem prop="repassword" class="captcha">
          <Input type="password" size="large" v-model="formInline.repassword" password
                 :placeholder="$t('uc.regist.repwd')">
          </Input>
        </FormItem>
        <FormItem prop="promotion" class="captcha">
          <Input type="text" size="large" v-model="formInline.promotion">
            <span slot="prepend">{{ $t('uc.regist.promotion') }} :</span>
          </Input>
        </FormItem>
        <FormItem class="captcha">
          <Button class="register_btn" @click="handleSubmit('formInline')" :disabled="registing">
            {{ $t('uc.regist.regist') }}
          </Button>
        </FormItem>
        <div class="bot" style="margin-top: 5px;margin-left: -10px;" @click="changeModal">
          <span style="color: #b1bad3;">{{ $t('common.login1') }}</span>
          <div style="color: #fff;">{{ $t('uc.login.login') }}</div>
        </div>
      </Form>
    </div>
  </Modal>
</template>

<script>
//   import gtInit from '../../assets/js/gt.js';

export default {
  data() {
    const validateUser = (rule, value, callback) => {
      if (this.changeActive == 0) {
        // var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (value == "") {
          callback(new Error(this.$t("uc.regist.teltip")));
        } else {
          callback();
        }
      } else {
        var reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
        reg = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/;
        if (value == "") {
          callback(new Error(this.$t("uc.regist.emailtip")));
        } else if (!reg.test(this.formInline.user)) {
          callback(new Error(this.$t("uc.regist.emailerr")));
        } else {
          callback();
        }
      }
    };
    const validateRepassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("uc.regist.confirmpwdtip")));
      } else if (value !== this.formInline.password) {
        callback(new Error(this.$t("uc.regist.confirmpwderr")));
      } else {
        callback();
      }
    };
    return {
      modal: false,
      from: '',
      country: "+855",
      country1: "台湾",
      codedisabled: false,
      sendcodeValue: this.$t("uc.regist.sendcode"),
      isRegister: false,
      ticket: "",
      randStr: "",
      registing: false,
      captchaObj: null,
      modal1: false,
      agree: true,
      allowRegister: true,
      buttonLists: [
        {
          text: this.$t("uc.regist.telregist")
        },
        {
          text: this.$t("uc.regist.emailregist")
        }
      ],
      areas: [],
      changeActive: 0,
      showCode: true,
      countdown: 60,
      formInline: {
        username: "",
        country: "",
        user: "",
        code: "",
        areaCode: "",
        password: "",
        repassword: "",
        promotion: ""
      },
      ruleInline: {
        user: [{validator: validateUser, trigger: "blur"}],
        code: [
          {
            required: true,
            message: this.$t("uc.regist.smscodetip"),
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: this.$t("uc.regist.pwdtip"),
            trigger: "blur"
          },
          {
            type: "string",
            min: 6,
            message: this.$t("uc.regist.pwdmsg"),
            trigger: "blur"
          }
        ],
        repassword: [{validator: validateRepassword, trigger: "blur"}]
      },
      key: "",
      code: "",
      timercode: ''
    };
  },
  watch: {
    changeActive: function () {
      this.$refs["formInline"].resetFields();
      // if (val == 0) this.initGtCaptcha();
    },
    lang: function () {
      this.updateLangData();
    },
    showModal(newVal, old) {
      this.modal = newVal
    },
    modal(newVal, old) {
      this.$store.commit("showRegisterFn", newVal)
    }
  },
  computed: {
    lang: function () {
      return this.$store.state.lang;
    },
    isLogin: function () {
      return this.$store.getters.isLogin;
    },
    showModal() {
      return this.$store.state.showRegister
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log(from)
    next(vm => {
      if (from.name === 'Home') {
        vm.from = 'Home'
      }
    })
  },
  created: function () {
    window.scrollTo(0, 0);
    this.actives(this.changeActive);
    if (this.$store.getters.currencyType != undefined && this.$store.getters.currencyType != "" && this.$store.getters.currencyType != null) {
      this.formInline.promotion = this.$store.getters.currencyType;
    } else {
      this.formInline.promotion = "";
    }
  },
  methods: {
    changeModal() {
      this.$store.commit('showLoginFn', true)
    },
    goback() {
      if (this.from === 'Home') {
        this.$router.push({path: "/"})
      } else {
        this.$router.back(-1)
      }

    },
    updateLangData() {
      this.buttonLists = [
        {
          text: this.$t("uc.regist.telregist")
        },
        {
          text: this.$t("uc.regist.emailregist")
        }
      ];

      if (this.changeActive == 0) {
        this.key = this.$t("uc.regist.telno");
      } else {
        this.key = this.$t("uc.regist.email");
      }
    },
    actives: function (index) {
      this.changeActive = index;
      if (this.changeActive == 0) {
        this.showCode = true;
        this.key = this.$t("uc.regist.telno");
        this.ruleInline.code = [
          {
            required: true,
            message: this.$t("uc.regist.smscodetip"),
            trigger: "blur"
          }
        ];
      } else {
        this.showCode = false;
        this.key = this.$t("uc.regist.email");
        this.ruleInline.code = [];
      }
    },
    getMemberInfo() {
      //获取个人安全信息
      let self = this;
      this.eloading = true
      this.$http
          .post(this.host + this.api.uc.memberInfo)
          .then(response => {
            var resp = response.body;
            self.eloading = false
            if (resp.code == "0000") {

              localStorage.setItem("MEMBER", JSON.stringify(null));
              self.$store.commit("setMember", resp.data);
              // self.coinType = resp.data.remark
              //  self.setCurrency();
              // self.configCode(self.coinType)
              this.$store.commit("showRegisterFn",false)
              self.$router.replace({path: '/'})

            } else {
              self.$Message.error(resp.mesg);
            }
          });
    },
    handleSubmit(name) {
      let that = this
      this.$refs[name].validate(valid => {
        if (valid) {
          if (this.agree == true) {
            if (this.changeActive == 1) {
              this.openValidateModal();
            } else {
              // if (this.isRegister) {
              this.registing = true;
              var params = {};
              let xy = this.country.substring(1, this.country.length)
              params["mobile"] = this.formInline.user;
              // params["username"] = this.formInline.username + this.formInline.user;
              params["password"] = this.formInline.password;
              params["agentId"] = this.formInline.promotion
              // 邀请码
              params["mask"] = this.formInline.code;
              params["code"] = xy;//"中国";
              params["deviceInfo"] = "3e3c41b2-62fd-48b3-b51e-fd202c19b456";
              params['ccy'] = 'USDT'

              this.$http
                  .post(this.host + this.api.uc.register, params)
                  .then(response => {
                    this.registing = false;
                    var resp = response.body;
                    if (resp.code == 200) {
                      that.$Notice.success({
                        title: this.$t("common.tip"),
                        desc: this.$t("uc.regist.success")
                      });
                      // that.$store.commit("setMember", resp.data);
                      that.$store.commit('setCode', xy);
                      localStorage.setItem('TOKEN', resp.data);
                      clearInterval(that.timercode);
                      that.getMemberInfo()

                    } else {
                      this.$Notice.error({
                        title: this.$t("common.tip"),
                        desc: resp.mesg
                      });
                    }
                  });
            }
          } else {
            this.$Notice.error({
              title: this.$t("common.tip"),
              desc: this.$t("uc.regist.agreementtip")
            });
          }
        } else {
          console.log("999");
        }
      });
    },
    settime() {
      this.sendcodeValue = this.$t("uc.regist.resendcode") + this.countdown + ")";
      this.codedisabled = true;
      var _this = this;
      _this.timercode = setInterval(() => {
        _this.countdown--;
        _this.sendcodeValue = _this.$t("uc.regist.resendcode") + _this.countdown + ")";
        if (this.countdown <= 0) {
          clearInterval(_this.timercode);
          _this.codedisabled = false;
          _this.sendcodeValue = _this.$t("uc.regist.sendcode");
          _this.countdown = 120;
        }
      }, 1000);
    },
    sendCode() {
      var params = {};
      let xy = this.country.substring(1, this.country.length)
      params["mobilePhone"] = this.formInline.user;
      params["areaCode"] = xy;
      params["sendType"] = 0;
      this.$http.post(this.host + this.api.uc.captcha, params).then(response => {
        var resp = response.body;
        resp.code == "0000" && this.$Notice.success({title: this.$t("common.tip"), desc: resp.mesg});
        resp.code == "0000" && this.settime();
        resp.code != "0000" && this.$Notice.error({title: this.$t("common.tip"), desc: resp.mesg});
      });
      // !reg.test(params["phone"]) &&this.$Notice.error({title: this.$t("common.tip"),desc: this.$t("uc.finance.withdraw.telerr")});
    }
  }
};
</script>
<style scoped lang="scss">
.login_right {
  background-color: #1A2C38;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  height: 500px;

  .reg {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
  }

  form.ivu-form.ivu-form-label-right.ivu-form-inline {
    .login_title {
      height: 70px;
      color: #fff;
      font-size: large;
    }
  }


  form.ivu-form.ivu-form-label-right.ivu-form-inline {
    .ivu-form-item {
      .ivu-form-item-content {
        #sendCode {
          position: absolute;
          //border: 1px solid #fff;
          background: transparent;
          top: -10px;
          outline: none;
          right: 0;
          width: 30%;
          color: #f0ac19;
          cursor: pointer;
          border-radius: 8px;
        }
      }
    }
  }
}

.register_btn.ivu-btn {
  width: 100%;
  height: 42px;
  border-radius: 4px;
  outline: none;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 15px;
  border: none;
  color: #000;
  font-weight: 600;
  background-color: #00E700;

  &:focus {
    -moz-box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
    -webkit-box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
    box-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff;
  }
}

/deep/ .ivu-input-group-prepend {
  color: #fff;
  border-radius: 8px;
  border: none;
  background-color: #0E212E;
  font-size: 12px;
}

/deep/ .ivu-select {
  color: #fff;
}

/deep/ .ivu-input-wrapper .ivu-input-wrapper-large .ivu-input-type-text {
  border-radius: 8px;
  border: 0px;
}

/deep/ .ivu-input {
  border-radius: 8px;
  border: none;
  background-color: #0E212E;
  color: #fff;
}

/deep/ .ivu-input::-moz-placeholder {
  color: $color-highlight-background;
  font-size: medium;
}

/deep/ .ivu-input::-webkit-input-placeholder {
  color: $color-highlight-background;
  font-size: medium;
}

/deep/ .ivu-input::-ms-input-placeholder {
  color: $color-highlight-background;
  font-size: medium;
}

.captcha {
  width: 90%;
  margin-left: 5%;
}

.bot {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}


/deep/ .ivu-form-item-content {
  border: 1px solid #2f4553;
  border-radius: 8px;
}

/deep/ .vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;
  }
}

/deep/ .ivu-modal-body {
  padding: 0;
  border-radius: 100px;
}

/deep/ .ivu-modal-content {
  width: 96%;
  margin: auto;
  border-radius: 20px;
}
</style>
